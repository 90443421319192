import React, { useState } from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx, useThemeUI, useColorMode } from "theme-ui"

import Stack from "../../components/stack"
import Text from "../../components/text"
import Button from "../../components/button"
import Box from "../../components/box"
import Heading from "../../components/heading"
import Inline from "../../components/inline"

import stampPositiveLight from "../../images/hegel-hoelderlin/stamp-positive-light.svg"
import stampNegativeLight from "../../images/hegel-hoelderlin/stamp-negative-light.svg"
import stampPositive from "../../images/hegel-hoelderlin/stamp-positive.svg"
import stampNegative from "../../images/hegel-hoelderlin/stamp-negative.svg"
import Columns from "../../components/columns"
import Column from "../../components/column"

const Rumor = (props) => {
  const { title, rumor, confirmed, children } = props

  const context = useThemeUI()
  const { theme } = context
  const [colorMode] = useColorMode()

  const [state, setState] = useState({
    isExpanded: false,
  })

  const toggleExpand = () => {
    setState({
      ...state,
      isExpanded: !state.isExpanded,
    })
  }

  return (
    <Box p={[6, 6, 12]} bg="muted" overflow="hidden">
      <Stack as="ul" space={6}>
        <Columns space={[6, 12]} reverse={true} collapse={[true, false]}>
          <Column width="content">
            <Box
              aria-hidden="true"
              borderRadius="lg"
              border={4}
              borderColor="border"
              borderStyle="dashed"
              width={[16, 24, 24, 32]}
              height={[16, 24, 24, 32]}
              display="flex"
              alignY="center"
              alignX="center"
              textAlign="center"
              position="relative"
            >
              <Box
                position="absolute"
                left={[-6, -6, -12]}
                top={[-6, -6, -12]}
                bottom={[-6, -6, -12]}
                right={[-6, -6, -12]}
                display="flex"
                alignY="center"
                alignX="center"
              >
                <img
                  src={
                    confirmed === true
                      ? colorMode === "light"
                        ? stampPositiveLight
                        : stampPositive
                      : colorMode === "light"
                      ? stampNegativeLight
                      : stampNegative
                  }
                  alt={
                    confirmed === true
                      ? "Stempel mit der Aufschrift Bestätigt"
                      : "Stempel mit der Aufschrift Legende"
                  }
                  width="200"
                  height="162"
                  sx={{
                    pointerEvents: "none",
                    transform: state.isExpanded ? "scale(1)" : "scale(3)",
                    opacity: state.isExpanded ? 1 : 0,
                    transition: `transform ${theme.transitions["fast"]}, opacity ${theme.transitions["fast"]}`,
                  }}
                />
              </Box>
            </Box>
          </Column>
          <Column>
            <Stack space={3}>
              <Heading as="h3" level={4}>
                {title}
              </Heading>
              <Text as="div" lang="de" hyphens="auto" size={[2, 3, 4]}>
                <Stack space={6}>{rumor}</Stack>
              </Text>
            </Stack>
          </Column>
        </Columns>

        {!state.isExpanded ? (
          <Inline alignY="baseline">
            <Button borderColor="positive" onClick={toggleExpand}>
              Wahrheit
            </Button>
            <Text bold sans>
              oder
            </Text>
            <Button borderColor="negative" onClick={toggleExpand}>
              Legende
            </Button>
            <Text bold sans>
              ?
            </Text>
          </Inline>
        ) : (
          <Heading
            level={5}
            color={confirmed === true ? "positive" : "negative"}
          >
            {confirmed === true
              ? "Das ist als Tatsache bezeugt."
              : "Es handelt sich um eine Legende."}
          </Heading>
        )}
        {state.isExpanded && (
          <Text as="div" lang="de" hyphens="auto" size={[2, 3, 4]}>
            <Stack space={6}>{children}</Stack>
          </Text>
        )}
      </Stack>
    </Box>
  )
}

export default Rumor
