import React from "react"

import Constrain from "../../components/constrain"
import Container from "../../components/container"
import Paragraph from "../../components/paragraph"
import Heading from "../../components/heading"
import Stack from "../../components/stack"
import Text from "../../components/text"

import Rumor from "./rumor"

const TuebingerGeruechte = (props) => {
  return (
    <Stack>
      <Constrain>
        <Stack>
          <Heading as="h2" level={2}>
            Tübinger Gerüchte
          </Heading>
          <Paragraph>
            Von Hölderlin und Hegel blieben an ihrem einstigen Studienort vor
            allem Gerüchte und Anekdoten zurück. Wahres und schlichtweg
            Erfundenes lässt sich dabei heute kaum noch voneinander
            unterscheiden. Für die Ausstellung haben wir diese Tübinger Gerüchte
            gesammelt und sind ihren Ursprüngen nachgegangen. Was glauben Sie?{" "}
            <Text as="em">
              Handelt es sich bei den folgenden Überlieferungen um Gerüchte oder
              Tatsachen
            </Text>
            ?
          </Paragraph>
        </Stack>
      </Constrain>
      <Container maxWidth="3xl">
        <Stack>
          <Rumor
            title="Der Freiheitsbaum"
            rumor="Zum Jahrestag der Französischen Revolution errichteten Hegel, Hölderlin und Schelling am 14. Juli 1793 auf dem Tübinger Marktplatz einen Freiheitsbaum und umtanzten ihn."
            confirmed={false}
          >
            <p>
              Der erste Freiheitsbaum der Geschichte, eine Ulme, wurde Mitte des
              18. Jahrhunderts in Amerika errichtet. Nachdem die Jakobiner 1790
              in Paris einen Baum mit einer roten Mütze gekrönt und mit
              Revolutionsliedern besungen hatten, wurde der Tanz um den
              Freiheitsbaum zum Kult. Das Gerücht um einen Tübinger
              Freiheitsbaum entstand, als der württembergische Herzog am
              Tübinger Stift ›Verdacht auf Democratismus‹ witterte. Um diesen
              Verdacht herunterzuspielen, berichtete ihm der Stiftsvorsteher:
              »Vor wenigen Monaten war mir aus Ulm geschrieben, es werde
              daselbst allgemein erzählt und geglaubt, daß die Stipendiaten
              selbst unter meinen Augen den Freiheitsbaum errichtet haben.« Er
              kündigte gar seinen Rücktritt an, wenn der Herzog diesen Gerüchten
              Glauben schenken würde.
            </p>
            <p>
              Unter den nachfolgenden Studentengenerationen wurde daraus eine
              historisch verbürgte Geschichte. Dazu trug auch Hegels
              Studienfreund Leutwein bei, der erzählte, Hegel, Schelling und
              einige andere seien eines Sonntagmorgens »auf eine Wiese unweit
              von Tübingen gezogen und hätten dort einen Freiheitsbaum
              aufgerichtet.«
            </p>
            <p>
              Hölderlins Biograf Christoph Theodor Schwab schrieb die
              Freiheitsbaumlegende fort, indem er berichtete, Hegel und
              Hölderlin hätten 1793 »auf dem Tübinger Marktplatz am Geburtstag
              der französischen Republik ein Freiheitsbaum errichtet und mit
              begeisterter Freude umjubelt.« Erst durch Schwab kamen also der
              Marktplatz und das Datum hinzu. Als Beleg für das Datum wurde auch
              ein Eintrag im Stammbuch ihres Studienfreundes Hiller
              herangezogen: »Lieber Hiller, denke zuweilen auch noch an den 14.
              Juli 1793, an Dein Gartenhäuschen, und an…« Zumindest Hegel aber
              war vom 10. Juli 1793 wegen Fiebers krankgeschrieben und zuhause
              in Stuttgart.
            </p>
          </Rumor>
          <Rumor
            title="Die Übersetzung der Marseillaise"
            rumor="Die französische Nationalhymne stand unter den Stipendiaten hoch im Kurs. Schelling hat sie verbotenerweise sogar ins Deutsche übersetzt."
            confirmed={false}
          >
            <p>
              … »Dieß war nun freilich nicht der Fall«, erklärt Schellings Sohn
              Karl Friedrich August in der Biografie über seinen Vater, »aber
              dem Herzog war so berichtet worden. Voll Aergers reist er sogleich
              nach Tübingen ab. Dort angekommen, hieß er die Stipendiaten im
              Speisesaal versammeln, Schelling und einige Berüchtigte mußten
              vortreten. Der Herzog hatte die Uebersetzung der Marseillaise in
              der Hand und hielt sie Schelling hin mit den Worten: ›
              <Text as="em">
                Da ist in Frankreich ein saubres Liedchen gedichtet worden, wird
                von Marseiller Banditen gesungen, kennt Er es[?]
              </Text>
              ‹ Dabei fixierte er ihn scharf und lange Zeit. Schelling aber sah
              den Herzog […] ebenso unbeweglich an. Die Unerschrockenheit gefiel
              dem Herzog so, daß er von weiteren Maßregeln abstand; doch
              unterließ er es nicht, noch eine kleine Strafrede an die
              Seminaristen zu halten, nach deren Beendigung er sich nochmals vor
              Schelling hinstellte und ihn fragte, ob ihm die Sache leid sey,
              worauf dieser geantwortet haben soll: ›
              <Text as="em">Durchlaucht, wir fehlen alle mannigfaltig</Text>.‹«
            </p>
            <p>
              Entstanden war die Marseillaise übrigens 1792 als ›Kriegslied für
              die Rheinarmee‹. Die wenige Monate später von Marseiller Soldaten
              beim Einzug nach Paris gesungenen Zeilen verbreiteten sich von da
              an in rasender Geschwindigkeit inner- und auch außerhalb
              Frankreichs. Bereits 3 Jahre später, am 14. Juli 1795, wurde die
              Marseillaise zur französischen Nationalhymne erklärt.
            </p>
          </Rumor>
          <Rumor
            title="Das Unsinnskollegium"
            rumor="Hegel und Schelling waren Mitglieder einer studentischen Vereinigung, die unter dem Namen ›Unsinnskollegium‹ bekannt war."
            confirmed={true}
          >
            <p>
              Das Unsinnskollegium, eine zuteilen auch politische Vereinigung,
              wurde von den Studenten der Tübinger Universität ins Leben
              gerufen. Man traf sich in Wirtshäusern, auf der Krankenstube des
              Stifts oder in den Studentenstuben und trug selbstverfasste
              Gedichte und Prosastücke vor. Auch Besucher waren, gegen ein
              entsprechendes Eintrittsgeld, willkommen. Nach Bekanntwerden
              dieser Zusammenkünfte wurde den Repetenten, die den Studenten dies
              durchgehen hatten lassen, ein Verweis erteilt und Nachlässigkeit
              vorgeworfen. »Unter der Hand vermutete man freilich auch, daß
              Comoedien gespielt, welche irreligiösen u. höchstprofanen Inhalts
              wären. Es existiere ein Clubb, in welchem über Religion gespottet
              u. verdiente Männer lächerlich gemacht werden«, heißt es dazu in
              den Repetentenannalen. Sicher gehörten Hegel und Schelling dieser
              Vereinigung an. Hegels Mitgliedschaft bezeugt ein Hinweis seines
              Freundes Griesinger im Stammbuch: Er schreibt dort von einem
              ›Collegium alogicum‹.
            </p>
          </Rumor>
          <Rumor
            title="Der politische ›Clubb‹"
            rumor="Um sich über die neusten Nachrichten aus Frankreich auszutauschen, trafen sich die Stipendiaten regelmäßig in einem geheimen politischen Club."
            confirmed={true}
          >
            <p>
              Initiiert durch die Stipendiaten aus dem französischen Mömpelgard
              (Montbéliard), das damals zum württembergischen Regierungsgebiet
              gehörte, bildete sich am Stift ein politscher Zirkel, der
              allerdings schon kurze Zeit später ein erstes Mal verraten wurde.
              Der Club hätte sich zum Ziel gesetzt, »Freyheit und Gleichheit im
              Land wie bey den Franzosen einzuführen«, so der Stuttgarter
              Geheimrat Fischer, der die Vereinigung nach Erhalt eines
              entsprechenden Hinweises am 30. April 1793 auffliegen ließ.
            </p>
            <p>
              Im Zirkel kursierten französische Zeitungen wie die Tageszeitung
              ›Le Moniteur Universel‹, die die Protokolle der
              Nationalversammlungen abdruckte, oder die
              revolutionär-demokratisch ausgerichtete Wochenzeitung ›Les
              Révolutions de Paris‹. Selbstverständlich zählte auch der
              leidenschaftliche Zeitungsleser Hegel unter die Mitglieder und
              nahm regen Anteil an den politischen Diskussionen. Noch 1794, als
              er das Stift bereits verlassen hatte, fragte er Schelling in einem
              Brief: »Lest Ihr noch französische Papiere?«
            </p>
            <p>
              Zur Unterbindung dieser Aktivitäten kam der Herzog eigens aus
              Stuttgart angereist, worauf Wetzel, der Rädelsführer des Clubs,
              heimlich aus dem Stift entfloh. Auch Schelling geriet ins Visier
              der Untersuchungen. Sein besorgter Vater, ein angesehener
              Orientalist, der als Lehrer an der Klosterschule Bebenhausen
              unterrichtete, schrieb gleich zwei Briefe an den Stiftsvorsteher
              Schnurrer, um den Sohn aus der Schlinge zu ziehen. Doch wird der
              Zirkel im geheimen wohl weiter bestanden haben.
            </p>
          </Rumor>
          <Rumor
            title="Der Frühstückswein"
            rumor="Statt Kaffee tranken die Studenten am Stift Wein zum Frühstück."
            confirmed={false}
          >
            <p>
              In dem an Weinbergen reichen Württemberg war der Tischwein fester
              Bestandteil aller Mahlzeiten. Schon den 14-jährigen Klosterschüler
              wurde er zum Essen vorgesetzt. Ein Frühstück war im Stipendium des
              Stift allerdings nicht enthalten. Hierfür hatten die Stipendiaten
              selbst zu sorgen. Tee- und Kaffeemaschinen waren in den Stuben
              zwar ausdrücklich verboten, doch ordert Hölderlin in den Briefen
              an seine Mutter regelmäßig Geld für ein wenig Kaffee und Brot.
            </p>
            <p>
              Geweckt wurden die Stipendiaten im Sommer um 5 Uhr, im Winter um
              6:30 Uhr. Das Mittagessen war auf 11 Uhr angesetzt. Zu dieser Zeit
              stand dann auch der erste Wein auf dem Tisch.
            </p>
          </Rumor>
          <Rumor
            title="Der Boulanger"
            rumor="Im Tübinger Weinlokal Boulanger ging Hegel bereits als Student ein und aus."
            confirmed={false}
          >
            <p>
              Das legt der Stammbucheintrag des Mömpelgarder Studienfreundes
              Fallot zunächst nahe, in dem von Wein und Butterbrezeln »chez le
              Boulanger« die Rede ist. Doch ist hier tatsächlich der ›Boulanger‹
              gemeint?
            </p>
            <p>
              Die heutige Gaststube ›Boulanger‹ existierte zu Hegels Studienzeit
              zwar bereits in Tübingen. Allerdings hieß sie damals noch
              ›Zillerei‹. Die nachfolgenden Wirte, das Ehepaar Kemmler, machten
              daraus 1796 eine Weinschenke mit Bäckerei, die sogenannte
              ›Kemmlerei‹. Der Name ›Boulanger‹ taucht in den städtischen Akten
              erst ab 1934 auf. Zu dieser Zeit bemühte sich der Enkel Ernst
              Kemmler um eine Erweiterung der Ausschank-Konzession ins 1.
              Stockwerk, was ihm nach anfänglichen Schwierigkeiten letztlich
              gewährt wurde.
            </p>
            <p>
              Fallot wird im Stammbuch also auf eine andere Tübinger Bäckerei
              (Boulanger) mit Weinausschank angespielt haben.
            </p>
          </Rumor>
          <Rumor
            title="Die Examensverweigerung"
            rumor="Der Jahrgangsbeste verweigerte 1793 die Teilnahme am öffentlichen Examen mit Berufung auf Kants Kategorischen Imperativ."
            confirmed={true}
          >
            <p>
              Karl Christoph Renz war der unangefochtene Primus des Jahrgangs
              und erhielt als solcher jedes Jahr eine Auszeichnung zum Examen.
              Als Herzog Karl Eugen im September 1793 höchstpersönlich bei den
              öffentlichen Examen zu Gast war, verweigerte Renz jedoch seine
              Teilnahme. Ein offensichtlicher politischer Protest gegen den
              Herzog. Am Folgetag schickte er eine Entschuldigung an das Ephorat
              des Stifts, in der er sich auf Kants Ethik bezog:
            </p>
            <p>
              »So sehr ich mich zu bescheiden weiß, daß es mir nicht zukommt,
              über irgend eine Verfügung meiner Obern mir ein Urteil anzumaßen;
              so sehr war mir bei der Veranstaltung des gestrigen Examens nach
              meinem individuellen Gefühl auch nur der Gedanke beugend, daß es
              Möglichkeit wäre, den Anschein zu erregen, als ob mich kleinlichte
              äußere Vortheile zu desto besserer Ausübung meiner Pflichten
              bestimmen könnten. Um auch nur diesen Schein zu umgehen, und
              hierinne nicht ganz gegen alle meine Grundsätze und Überzeugung,
              die mir jederzeit theuer ist, handeln zu müssen, that ich den
              Schritt […] wegen dem ich Ihnen diese Verantwortung zuschike.«
              Tatsächlich war das Ansehen des Jahrgangsbesten so hoch, dass er
              davon keine weiteren Strafen davontrug.
            </p>
            <p>
              Hegel und Schelling verfolgten Renz‘ Werdegang noch lange.
              Schelling setzte sich 1812 sogar für seine Berufung an die
              Würzburger Universität ein. Doch anders als diese beiden trat Renz
              nach dem Abschlussexamen am Stift in den Pfarrdienst ein und ließ
              kaum noch von sich hören.
            </p>
          </Rumor>
        </Stack>
      </Container>
    </Stack>
  )
}

export default TuebingerGeruechte
