import React from "react"

import Stack from "../../components/stack"
import Text from "../../components/text"
import Box from "../../components/box"
import Heading from "../../components/heading"

const Letter = (props) => {
  const { date, children, variant, location, title } = props
  return (
    <Box p={[4, 6, 6, 8, 12]} bg={`letters.${variant}`}>
      <Stack space={6}>
        <Stack space={3}>
          <Heading as="div" level={6} color="black" bold={false}>
            {location}, {date}
          </Heading>
          <Heading as="h3" level={3} color="black">
            {title}
          </Heading>
        </Stack>
        <Text
          as="div"
          lang="de"
          hyphens="auto"
          color="black"
          size={[2, 3, 3, 4]}
        >
          <Stack space={6}>{children}</Stack>
        </Text>
      </Stack>
    </Box>
  )
}

export default Letter
