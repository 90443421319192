import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Box from "../../components/box"
import Breakout from "../../components/breakout"
import Constrain from "../../components/constrain"
import Container from "../../components/container"
import Paragraph from "../../components/paragraph"
import Grid from "../../components/grid"
import Heading from "../../components/heading"
import Stack from "../../components/stack"
import Text from "../../components/text"
import VideoPlayer from "../../components/video-player"

const Begegnungen = (props) => {
  const data = useStaticQuery(graphql`
    query HegelBegegnungenQuery {
      poster1: file(
        relativePath: { eq: "hegel-hoelderlin/poster-hegel-01.jpg" }
      ) {
        ...largeImage
      }
      poster2: file(
        relativePath: { eq: "hegel-hoelderlin/poster-hegel-02.jpg" }
      ) {
        ...largeImage
      }
      poster3: file(
        relativePath: { eq: "hegel-hoelderlin/poster-hegel-03.jpg" }
      ) {
        ...largeImage
      }
      poster4: file(
        relativePath: { eq: "hegel-hoelderlin/poster-hegel-04.jpg" }
      ) {
        ...largeImage
      }
      poster5: file(
        relativePath: { eq: "hegel-hoelderlin/poster-hegel-05.jpg" }
      ) {
        ...largeImage
      }
      poster6: file(
        relativePath: { eq: "hegel-hoelderlin/poster-hegel-06.jpg" }
      ) {
        ...largeImage
      }
      poster7: file(
        relativePath: { eq: "hegel-hoelderlin/poster-hegel-07.jpg" }
      ) {
        ...largeImage
      }
    }
  `)
  return (
    <Stack space={[12, 24]}>
      <Constrain>
        <Stack>
          <Stack>
            <Heading as="h2" level={2}>
              Begegnungen mit Hegel und Hölderlin
            </Heading>
            <Paragraph>
              Zur Ausstellungseröffnung blicken Tübinger Philosophie-Studierende
              von heute auf die Philsophie-Studierenden von einst zurück und
              lesen aus eigens dazu verfassten Texten.
            </Paragraph>
          </Stack>
          <Breakout>
            <Box px={[6, 6, 12]}>
              <Container>
                <Grid columns={[1, 2, 2, 3]} alignX="center">
                  <Stack space={6}>
                    <VideoPlayer
                      src="hegel-hoelderlin-1"
                      poster={data.poster1}
                    />
                    <Stack space="2">
                      <Text size={[3, 4]} align="center" sans bold>
                        Einführung in die Ausstellung
                      </Text>
                      <Text size={2} color="whisper" align="center">
                        Dr. Sandra Potsch, Ehemalige Leiterin des Hölderlinturms
                      </Text>
                    </Stack>
                  </Stack>

                  <Stack space={6}>
                    <VideoPlayer
                      src="hegel-hoelderlin-2"
                      poster={data.poster2}
                    />
                    <Stack space="2">
                      <Text size={[3, 4]} align="center" sans bold>
                        Hegel & Hölderlin theoretisch
                      </Text>
                      <Text size={2} color="whisper" align="center">
                        Prof. Dr. Ulrich Schlösser, Lehrstuhlinhaber am
                        Philosophischen Seminar Tübingen
                      </Text>
                    </Stack>
                  </Stack>
                  <Stack space={6}>
                    <VideoPlayer
                      src="hegel-hoelderlin-3"
                      poster={data.poster3}
                    />
                    <Stack space="2">
                      <Text size={[3, 4]} align="center" sans bold>
                        Hegel lesen. Ein Erfahrungsbericht
                      </Text>
                      <Text size={2} color="whisper" align="center">
                        Ana Munte, Philosophie-Studentin in Tübingen
                      </Text>
                    </Stack>
                  </Stack>
                  <Stack space={6}>
                    <VideoPlayer
                      src="hegel-hoelderlin-4"
                      poster={data.poster4}
                    />
                    <Stack space="2">
                      <Text size={[3, 4]} align="center" sans bold>
                        Unendliche Scham
                      </Text>
                      <Text size={2} color="whisper" align="center">
                        Anna-Lisa Sander, Philosophie-Studentin in Tübingen
                      </Text>
                    </Stack>
                  </Stack>
                  <Stack space={6}>
                    <VideoPlayer
                      src="hegel-hoelderlin-5"
                      poster={data.poster5}
                    />
                    <Stack space="2">
                      <Text size={[3, 4]} align="center" sans bold>
                        Geisterfeuer
                      </Text>
                      <Text size={2} color="whisper" align="center">
                        Emma Kroll-Reiser, Philosophie-Studentin in Tübingen
                      </Text>
                    </Stack>
                  </Stack>
                  <Stack space={6}>
                    <VideoPlayer
                      src="hegel-hoelderlin-6"
                      poster={data.poster6}
                    />
                    <Stack space="2">
                      <Text size={[3, 4]} align="center" sans bold>
                        Von eindrücklichen Zeichen und mittäglichen Ansagen
                      </Text>
                      <Text size={2} color="whisper" align="center">
                        Anita Watzel, Philosophie-Studentin in Tübingen
                      </Text>
                    </Stack>
                  </Stack>
                  <Stack space={6}>
                    <VideoPlayer
                      src="hegel-hoelderlin-7"
                      poster={data.poster7}
                    />
                    <Stack space="2">
                      <Text size={[3, 4]} align="center" sans bold>
                        Der unendliche Schmerz
                      </Text>
                      <Text size={2} color="whisper" align="center">
                        Florian Neuner, Philosophie-Doktorand in Tübingen
                      </Text>
                    </Stack>
                  </Stack>
                </Grid>
              </Container>
            </Box>
          </Breakout>
        </Stack>
      </Constrain>
    </Stack>
  )
}

export default Begegnungen
